import { ReactElement } from 'react';
import { ICON_TYPE } from '@client/common/components/InfoView';
import { NotFoundPage as CoreNotFoundPage } from '@client/common/components/NotFoundPage';
import image from './assets/brokenVase.jpg';
import retinaImage from './assets/brokenVase@2x.jpg';

export const NotFoundPage = (): ReactElement => (
  <CoreNotFoundPage
    iconType={ICON_TYPE.error}
    image={{
      default: image,
      retina: retinaImage,
    }}
  />
);
