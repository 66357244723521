import { ReactElement } from 'react';
import { Footer as CoreFooter } from '@client/app/components/App/Footer';
import { useUserData } from '@client/context/hooks';

export const Footer = (): ReactElement => {
  const { isLoggedIn } = useUserData();

  return <CoreFooter hasNewsletterDisplayed={isLoggedIn} />;
};

