import { ReactNode } from 'react';

export enum ICON_TYPE {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

type ButtonType = {
  content: ReactNode;
  onClick: () => any;
};

export type ButtonsType = {
  primary: ButtonType;
  secondary?: ButtonType;
};

export type ImageType =
  | string
  | {
  default: string;
  retina: string;
};

export interface Props {
  buttons?: ButtonsType;
  className?: string;
  iconType?: ICON_TYPE;
  image: ImageType;
  title: string;
  message?: ReactNode;
  testId?: string;
  buttonsGroupClassName?: string;
}
