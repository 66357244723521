import { ReactElement } from 'react';
import { ICON_TYPE } from '@client/common/components/InfoView';
import { UnloggedUserPage as CoreUnloggedUserPage } from '@client/common/components/UnloggedUserPage';
import image from './assets/lock.png';
import retinaImage from './assets/lock@2x.png';

export const UnloggedUserPage = (): ReactElement => (
  <CoreUnloggedUserPage
    iconType={ICON_TYPE.error}
    image={{
      default: image,
      retina: retinaImage,
    }}
  />
);
