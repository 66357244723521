import { ReactElement } from 'react';
import { ModalMenu as CoreModalMenu, Props } from '@client/app/components/App/Header/ModalMenu';
import { useBrands } from '@client/common/hooks/useBrands';

export const ModalMenu = (props: Props): ReactElement => {
  const [brands] = useBrands(false);

  return <CoreModalMenu {...props} brands={brands} />;
};

