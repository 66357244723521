import { ReactElement } from 'react';
import { ICON_TYPE } from '@client/common/components/InfoView';
import { InvalidPermissionsPage as CoreInvalidPermissionsPage } from '@client/common/components/InvalidPermissionsPage';
import image from './assets/lock.png';
import retinaImage from './assets/lock@2x.png';

export const InvalidPermissionsPage = (): ReactElement => (
  <CoreInvalidPermissionsPage
    iconType={ICON_TYPE.error}
    image={{
      default: image,
      retina: retinaImage,
    }}
  />
);
